import { StaticImage } from 'gatsby-plugin-image';
// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Colors from '../Colors';
import DiscoverLink from '../buttons/DiscoverLink';
import HeadsetSvgIcon from '../../images/icons/headset.inline.svg';

type FindOutMoreProps = {
  hideButtonCall: boolean;
  bookingUrl: string;
};

const FindOutMore: React.VoidFunctionComponent<FindOutMoreProps> = ({
  hideButtonCall,
  bookingUrl,
}) => {
  const { t } = useTranslation('academy');
  const altImage = t('find-out-more.image-alt');
  const trainingCatalogUrl = 'https://tally.so/r/3NBpjb';
  const workshopCatalogUrl = 'https://tally.so/r/3x0Ked';

  return (
    <section id="find-out-more" className="relative">
      <div className={Colors.WHITE_ON_BLUE}>
        <div className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 pt-16 sm:px-8">
          <div className="lg:w-2/3">
            <h2 className="text-left text-4xl font-semibold">{t('find-out-more.title')}</h2>
            <p>{t('find-out-more.description')}</p>
          </div>
        </div>
        <div className="hidden lg:block">
          <StaticImage
            src="../../images/academy/catalogue.png"
            className="right-0 top-0 h-4/5 w-96 xl:right-1/10"
            title={altImage}
            alt={altImage}
            placeholder="blurred"
            style={{ position: 'absolute' }}
          />
        </div>
      </div>
      <div
        className={`container hidden h-full max-w-224 flex-col justify-center px-4 pb-8 pt-16 sm:px-8 lg:flex ${Colors.BLUE_ON_WHITE}`}
      >
        <div>
          <DiscoverLink
            text={t('introduction.download-catalog-trainings')}
            href={trainingCatalogUrl}
            pseudoClass={`mt-4 mr-4 ${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
            svgClass="rotate-90"
          />
          <DiscoverLink
            text={t('introduction.download-catalog-workshops')}
            href={workshopCatalogUrl}
            pseudoClass={`${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
            svgClass="rotate-90"
          />
        </div>
        {hideButtonCall ? null : (
          <div>
            <DiscoverLink
              text={t('we-talk.button-call')}
              href={bookingUrl}
              pseudoClass={`mt-4 mr-4 ${Colors.BLUE_ON_WHITE} ${Colors.BLUE_ON_WHITE_PSEUDO_CLASS}`}
              SvgIcon={HeadsetSvgIcon}
            />
          </div>
        )}
      </div>
      <div className={`px-4 pb-8 sm:px-8 lg:hidden ${Colors.WHITE_ON_BLUE}`}>
        <div>
          <DiscoverLink
            text={t('introduction.download-catalog-trainings')}
            href={trainingCatalogUrl}
            pseudoClass={Colors.BLUE_ON_WHITE}
            svgClass="rotate-90"
          />
        </div>
        <div>
          <DiscoverLink
            text={t('introduction.download-catalog-workshops')}
            href={workshopCatalogUrl}
            pseudoClass={`mt-4 ${Colors.BLUE_ON_WHITE}`}
            svgClass="rotate-90"
          />
        </div>
        {hideButtonCall ? null : (
          <div>
            <DiscoverLink
              text={t('we-talk.button-call')}
              href={bookingUrl}
              pseudoClass={`mt-4 mr-4 ${Colors.WHITE_ON_BLUE}`}
              SvgIcon={HeadsetSvgIcon}
            />
          </div>
        )}
      </div>
      <div
        className={`lg:hidden ${Colors.BLUE_ON_WHITE}`}
        style={{ background: 'linear-gradient(0deg, #FFF 60%, rgb(11 39 245) 60%)' }}
      >
        <StaticImage
          src="../../images/academy/catalogue.png"
          className="h-full w-full"
          title={altImage}
          alt={altImage}
          placeholder="blurred"
        />
      </div>
    </section>
  );
};

export default FindOutMore;
